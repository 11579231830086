// Basic Colors
$white: #FFFFFF;
$black: #000000;

// Main Brand Color - Blue
$brandcolor1-extralight: #ced4da;
$brandcolor1-light: #809bc4;
$brandcolor1: #495a88;
$brandcolor1-dark: #252f45;

// Secondary Brand Color - Green
$brandcolor2-extralight: #ebf0ec;
$brandcolor2-light: #d3e3d7;
$brandcolor2: #91af97;
$brandcolor2-dark: #5c7e64;

// Action Item Brand Color - Orange
$brandcolor3-extralight: #f0edeb;
$brandcolor3-light: #ed5e0c;
$brandcolor3: #b95a00;
$brandcolor3-dark: #873600;

//Alert Colors
$alertcolor1-light: #FBE3E4;
$alertcolor1: #a94442;
$alertcolor1-dark: #D12F19;

// Grays
$gray-white1: #f7f7f7;
$gray-white2: #f3f3f3;
$gray-white3: #ededed;

$gray-light1: #e5e5e5;
$gray-light2: #d9d9d9;
$gray-light3: #bfbfbf;

$gray-mid1: #a6a6a6;
$gray-mid2: #8c8c8c;
$gray-mid3: #737373;

$gray-dark1: #5a5a5a;
$gray-dark2: #404040;
$gray-dark3: #262626;