@import "abstracts/variables";
@import "legacy/slider";

$purple: $brandcolor1;
$midPurple: $brandcolor1-light;
$ltPurple: $brandcolor1-light;
$dkPurple: $brandcolor1-dark;
$grey: $gray-light1;
$midGrey: $gray-mid3;
$darkGrey: $gray-dark1;
$green: $brandcolor2;
$dkGreen: $brandcolor2-dark;
$orange: $brandcolor3;
$ltOrange: $brandcolor3-light;
$dkOrange: $brandcolor3-dark;

$S:     768px;
$M:     992px;
$L:     1200px;

$util-height: 70px;

@mixin MQ($canvas) {
	@if $canvas == S {
	 @media only screen and (min-width: $S) { @content; }
	}
	@else if $canvas == M {
	 @media only screen and (min-width: $M) { @content; }
	}
	@else if $canvas == L {
	 @media only screen and (min-width: $L) { @content; }
	}
	@else if $canvas == XL {
	 @media only screen and (min-width: $XL) { @content; }
	}
}
// *, *::after, *::before {@include box-sizing(border-box);}
html * { -webkit-font-smoothing: antialiased; -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px; text-shadow: rgba(0,0,0,.01) 0 0 1px;}
body {font-size:16px;}
footer {font-size:14px;}
//footer callout add padding
.callout {
	h4 {font-size:36px}
	p {font-size:28px;}
	.btn-primary {border:2px solid $brandcolor1-light;}
	@include MQ(M) {padding:60px 15px;}
}
//homepage remove tree
.home-content {background-image:none !important;
	.welcome { border:none;}
}
// fix button alignment with icons
.btn-group-vertical .btn-primary i {margin-right:-50px;}
// new contribution callouts
.callouts {border-top:1px solid $gray-light1; margin-top:0; padding-top:40px;
	h2 {padding:0 15px;
		span {color:$gray-dark1;}
		@include MQ(M) {text-align: left; font-size:40px; font-weight: 600; margin:0 0 30px;
			span {font-size:28px;}
		}
	}
	.caption {padding:0 30px 50px;}
	.btn-primary {margin-bottom:-70px;}
}
// updated page title
.page-title {background-color:$gray-dark3; background-image:url(/stylesheets/webart/header-bkg/faces-4.jpg); background-size:cover; background-repeat:no-repeat; background-position: center center; background-attachment: inherit;
	@include MQ(M) {padding:20px 0;
		h1 {font-size:36px;}
	}
}


.carousel-overlay {
 @include MQ(M) {padding:100px 40px;}
 @include MQ(L) {padding:140px 220px;}
}

//images
.image-cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.image-contain {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}
.embed-responsive {
	img.embed-responsive-item {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
	}
}

section.faces-of-donation {background:$brandcolor2-extralight; padding:40px 0; text-align: center;
	h2 {position:relative; z-index: 100; display:inline-block; padding:10px 15px; font-size:24px; background:$brandcolor1; color:$white; text-transform: uppercase; margin:0 auto -20px;}
	@include MQ(M) {padding:80px 0;
		h2 {margin-bottom:-60px;  font-size:36px;}
		.slider-content {
				.slider-text {
					h3 {font-size:28px;}
					p  {font-size:16px;}
				}
			}
	}
	.slider-content { padding:20px;
		 @include MQ(M) {text-align:left; padding:40px;}
	}
	a.btn-primary {border-radius: 0;}
	> a.btn-primary {background:none; border:2px solid $brandcolor2; color:$brandcolor2; transition:all 0.3s ease;
			&:hover, &:active {background:$brandcolor2; color:$white;}
	}
}
// Main Navigation
.navbar {
	background:none;
	padding:0;
	#main-nav {
		.dropdown-toggle::after {
			display: none;
		}
	}
	.navbar-nav {

		.nav-link, .nav-link:focus, .nav-link:hover {
			color: $white;
		}
		width:100%;
		margin: 7.5px -15px;
		margin-left: auto;
		margin-right: auto;
		background:#e7e7e7;
		> .nav-item {
			flex-shrink: 0;
			width:100%;
			text-align: center;
			@include MQ(S){width:25%;}
			&:last-child {
				> .nav-link {
					margin-right:0;
				}
			}
			&.dropdown {
				&.active {
					> a {
						background:$brandcolor2-dark;
						&:hover, &.active, &:focus, &:active {
							background:$brandcolor2-dark; color:$white;
						}
					}
				}
				> .nav-link {
					background:$brandcolor2;
					border-bottom:5px solid $brandcolor2-dark;
					&:hover, &.active, &:focus, &:active {
						background:$brandcolor2-dark; color:$white;
					}
				}
			}
			&.active {
				> .nav-link {background:$brandcolor3-dark; color:white;
					&:hover, &.active , &:focus, &:active {background:$brandcolor3-dark; color:white;}
				}
			}
			> .nav-link {background:$brandcolor3; border-bottom:5px solid $brandcolor3-dark; color:$white; font-size:22px; transition:all 0.3s ease;
					&:hover, &.active, &:focus, &:active {background:$brandcolor3-dark; color:white;}
					i {font-size:14px;}
					@include MQ(M) {margin-right:2px;}
			}
			> .dropdown {position: static; width:100%;}
		}
		@include MQ (S) {margin-bottom:-5px;}
		@include MQ(L) {width:80%;}
	}
}

// Statistics Bar
.utility-statistics {padding:10px; background:$brandcolor1; text-align: center;
	p {margin:0; padding:0; color: rgba(white,0.65); font-size:14px; transition:all 0.3s ease;
		span {display:block;}
		strong {color:white; border-bottom:1px dotted rgba(255,255,255,0.25);}
		 @include MQ(S) {
			span {display:inline;}
		 }
		 @include MQ(M) {font-size:18px;}
	}
}

// Utility Navigation
nav.utility {height:$util-height*.75; font-size:14px; text-align: center; background:$brandcolor1;
	@include MQ(S) {background:none;}
	.navbar-search {
		display:none;
		@include MQ(S) {
			display:inline-block; float: right; margin:10px 0;
		}
	}
	ul.list-inline {margin-bottom:0; line-height: $util-height*.75; margin:0;
		 @include MQ(M) {background:none;}
		&.list-inline-nav {
			> li {
				> a {color:$white; transition:all 0.3s ease;
					@include MQ(S) {color:$gray-dark1;}
					&:hover, &:active {color:$brandcolor3; text-decoration: none;}
				}
			}
		}
		&.list-inline-social { display:none; margin:0 20px;
			> li {padding:0;
				> a {color:$gray-mid3; display:inline-block; padding:0 4px;
					&:hover, &:active {color:$brandcolor3;}
				}
			}
			@include MQ(S) {display: inline-block;}
		}
		@include MQ(S) {display: inline-block; float:right; line-height: $util-height;}
		@include MQ(M) {float:right;}
	}
	@include MQ(S) {height:$util-height;}
}

.search-form {
	.input-group {
		input[type="text"],
		input[type="search"] {
			&.form-control {
				height: auto;
			}
		}
	}
}

.idn-nav {
	// reset positions
	.nav, .collapse, .dropup, .dropdown {
		position: static;
	}

	// propagate menu position under container for fw navbars
	.container {
		position: relative;
	}

	// by default aligns menu to left
	.dropdown-menu {
		left: auto;
		top:inherit;
		text-align: left;
		> li {
			width: auto;
			a {
				color: inherit;
				font-size: inherit;
				background: none;
			}
		}
	}

	// Content with padding
	.idn-nav-content {
		padding: 20px 30px;
		ul {
			> li {
				h5 {font-weight: normal; color:$brandcolor1; font-size:20px; border-bottom:1px dotted $brandcolor1;}
				a {color:$gray-mid3;
					&:hover, &:active, &:focus {color:$brandcolor3;}
				}
			}
		}
	}

	// Fullwidth menu
	.dropdown.idn-nav-fw .dropdown-menu {
		left: 0; right: 0;
	}

}
#mobile-alert button.close {
	display: none;
}
#mobile-alert .utility .container {
	padding: 0;
}
#mobile-alert .navbar {
	border: none;
}
#mobile-alert .navbar-header .navbar-brand {
	margin-top: 15px;
	margin-bottom: -15px;
	width: 130px;
}
#mobile-alert .navbar .navbar-header .navbar-toggle.collapsed span {
	background: #888888;
}
.navbar-nav .visible-xs form#search {
	line-height:normal;
	border-bottom: 5px solid #5c7e63;
}
.jumbotron {
	position: relative;
	z-index: 1 !important;
}
.jumbotron .jumbotron-overlay {
	position: absolute;
}
.jumbotron .jumbotron-bkg {
	position: absolute;
	z-index: 1;
}
.jumbotron .container {
	padding: 0;
}
.alert-phone {
	padding: 0 10px 0 10px;
}
.dropdown-menu .idn-nav-content {
	line-height: 2.5;
}

// .event-date-card {
// 	display: inline-block;
// 	border-top: 1.25rem solid $brandcolor1;
// 	background: linear-gradient(to bottom, transparent 0, transparent 2px, $brandcolor2 2px, $brandcolor2 100%);
// 	text-align: center;
// 	color: $white;
// 	padding: 1em 0;
// 	text-transform: uppercase;
// 	line-height: 1;
// 	font-weight: 700;
// 	.event-month,
// 	.event-year {
// 		font-size: 0.9em;
// 	}
// 	.event-day {
// 		font-size: 2.8em;
// 		margin: 0.1rem 0;
// 		width: 2.5em;
// 	}
// }
// .event-detail-label {
// 	color: $brandcolor1-light;
// 	font-weight: 600;
// }
// .featured-event {
// 	background-color: $brandcolor3-extralight;
// 	padding: 20px 0;
// 	margin: 0 0 -16px -15px;
// 	.event-date-card {

// 		// background: linear-gradient(to bottom, transparent 0, transparent 2px, $brandcolor2-dark 2px, $brandcolor2-dark 100%);
// 	}
// 	h3 {
// 		font-weight: 600;
// 	}
// 	h4 {
// 		color: $brandcolor1;
// 	}
// }
// hr {
// 	&.divider-brand {
// 		border-color: $brandcolor3;
// 	}
// 	&.divider-brand-alt {
// 		border-color: $brandcolor2;
// 	}
// }

.pagination {
	border: none;
	text-transform: lowercase;
	font-weight: bold;
	.page-item {
		& + .page-item {
			border-left: 1px solid $gray-light1;
		}
		.page-link {
			background: none;
			border: none;
			padding: .5rem 1.25rem;
			color: $brandcolor3;
			i {
				color: $brandcolor3;
			}
		}
		&.active {
			.page-link,
			.page-link i {
				color: $gray-dark1;
			}
		}
		&.disabled {
			.page-link,
			.page-link i {
				color: $gray-mid3;
			}
		}
	}
}

.entry-pages-newsroom {
	.article-link {
		font-weight: bold;
		text-transform: uppercase;
		border-bottom: none;
	}
	.post-date {
		font-weight: bold;
		text-transform: uppercase;
		color: $gray-mid3;
		margin: 1rem 0 2rem 0;
	}
	.article-card {
		margin: 0 0 20px 0;
		height: auto;
		border: none;
		&:hover {
			background-color: $gray-white1;
		}
		.post-date {
			margin: 0 0 0.5rem 0;
		}
		.card-title {
			color: $brandcolor1;
		}
		.card-text {
			margin-bottom: 3rem;
		}
		.article-link {
			position: absolute;
			bottom: 1.25rem;
			left: 1.25rem;
		}
		.stretched-link {
			width: 0;
			height: 0;
			padding: 0;
			margin: 0;
		}
	}
}

.share-bar {
	.share-label {
		margin-right: 1.5rem;
	}
	.print-label {
		margin-right: 1.5rem;
	}
	.share-label,
	.print-label {
		color: $gray-mid3;
		text-transform: uppercase;
		font-size: 0.8em;
		font-weight: bold;
	}
	.addthis_inline_share_toolbox {
		border-left: 1px solid $gray-light1;
		padding-left: 1rem;
	}
	.print-link {
		cursor: pointer;
	}
}

.entry-type-careerOpportunities {
	.main {
		ul {
			li {
				&::before {
					display: none;
				}
				&.list-group-item {
					margin-bottom: 0;
				}
			}
		}
	}
}

.page-content {
	.main {
		position: relative;
		> * {
			position: relative;
			z-index: 5;
		}
		> .body-background-container {
			z-index: 1;
		}
	}
}

@media (max-width: 767.9px) {
	.page-title,
	footer {
		.container, .container-sm {
			max-width: none;
		}
	}
}
@media (min-width: 576px) {
	.page-content,
	.navbar {
		.container, .container-sm {
			// max-width: none;
		}
	}
}
